import { CompanyServices } from '@services/company-services';
import { defineStore } from 'pinia';
import { EventLogger } from '@utils/event-metrics';
import { useInterface } from '@stores';
import { EVENTS_DICTIONARY } from '@assets/constants/events-dictionary';
import { TravelsPageName } from '@assets/constants/route-names';
import { useTravelsPage } from './travels-page-store';

const events = EVENTS_DICTIONARY[TravelsPageName];

export const useTravelBuses = defineStore('TravelBuses', {
  state: () => ({
    error: null,
    newBus: null,
    group: null,
    isInvalidForm: null,
    isModalOpen: false,
    isConfirmationOpen: false,
    currentFreightValue: null,
    newFreightValue: null,
    invoiceIssueDate: null,
    reasonPlateExchance: null,
    explanationPlateExchance: null,
  }),
  getters: {
    currentBus(state) {
      return state?.group?.onibus;
    },
    busError(state) {
      return state.error.message;
    },
    busStatus(state) {
      return state.error.status;
    },
    needApproval() {
      return this.busStatus === 'PRECISA_APROVACAO';
    },
  },
  actions: {
    openEditBusModal(group, newBus, error) {
      this.group = group;
      this.newBus = newBus;
      this.error = error;
      this.isModalOpen = true;
    },
    openConfirmation(group, newBus) {
      this.group = group;
      this.newBus = newBus;
      this.isConfirmationOpen = true;
    },
    close() {
      this.error = null;
      this.newBus = null;
      this.group = null;
      this.isInvalidForm = null;
      this.isModalOpen = false;
      this.isConfirmationOpen = false;
      this.currentFreightValue = null;
      this.newFreightValue = null;
      this.invoiceIssueDate = null;
      this.reasonPlateExchance = null;
      this.explanationPlateExchance = null;
    },
    askSetGroupBus() {
      const $interfaceState = useInterface();
      const TravelsPageStore = useTravelsPage();

      return CompanyServices.askSetGroupBus(
        this.newBus?.id,
        this.group?.id,
        this.reasonPlateExchance,
        this.explanationPlateExchance,
      )
        .then(() => {
          TravelsPageStore.requestGroups();
          $interfaceState.toast.add({ label: 'Troca de ônibus solicitada', color: 'success' });
          this.close();
        })
        .catch((error) => {
          const errorMessage = error?.response?.data?.error || error.message;
          $interfaceState.toast.add({ label: errorMessage, color: 'danger' });
        });
    },
    trySetBus(group, bus) {
      const $interfaceState = useInterface();
      const TravelsPageStore = useTravelsPage();
      this.isConfirmationOpen = false;

      CompanyServices.setGroupBus(
        bus?.id,
        group?.id,
      )
        .then(() => {
          $interfaceState.toast.add({ label: 'Ônibus escalado com sucesso', color: 'success' });
          TravelsPageStore.requestGroups();
        })
        .catch((error) => {
          const OnlyToastErrors = ['DOCUMENTACAO_NAO_APROVADA', 'MICRO_ONIBUS_OR_VAN']
          if (OnlyToastErrors.includes(error.status)) {
            $interfaceState.toast.add({ label: error.message, color: 'danger' });
          } else {
            this.openEditBusModal(group, bus, error);
          }
        });
    },
  },
});

const banks = [
  {
    value: '001',
    text: '001 - Banco do Brasil',
  },
  {
    value: '104',
    text: '104 - Caixa Econ\u00f4mica Federal',
  },
  {
    value: '341',
    text: '341 - Banco Ita\u00fa S.A.',
  },
  {
    value: '17192451',
    text: '341 - Banco Itaucard S.A. (iti)',
  },
  {
    value: '237',
    text: '237 - Bradesco',
  },
  {
    value: '033',
    text: '033 - Santander',
  },
  {
    value: '260',
    text: '260 - NuBank',
  },
  {
    value: '077',
    text: '077 - Banco Inter',
  },
  {
    value: '003',
    text: '003 - Banco da Amaz\u00f4nia',
  },
  {
    value: '004',
    text: '004 - Banco do Nordeste',
  },
  {
    value: '007',
    text: '007 - Bnds',
  },
  {
    value: '010',
    text: '010 - Credicoamo',
  },
  {
    value: '011',
    text: '011 - Credit Suisse Hedging-Griffo',
  },
  {
    value: '012',
    text: '012 - Banco Inbursa',
  },
  {
    value: '014',
    text: '014 - Natixis',
  },
  {
    value: '015',
    text: '015 - UBS',
  },
  {
    value: '016',
    text: '016 - Creditran',
  },
  {
    value: '017',
    text: '017 - BNY Mellon Banco',
  },
  {
    value: '018',
    text: '018 - Banco Tricury',
  },
  {
    value: '021',
    text: '021 - Banestes',
  },
  {
    value: '024',
    text: '024 - Banco Bandepe',
  },
  {
    value: '025',
    text: '025 - Banco Alfa',
  },
  {
    value: '029',
    text: '029 - Banco Ita\u00fa Consignado',
  },
  {
    value: '036',
    text: '036 - Banco Bradesco BBI',
  },
  {
    value: '037',
    text: '037 - Banco do Estado do Par\u00e1',
  },
  {
    value: '040',
    text: '040 - Banco Cargill',
  },
  {
    value: '041',
    text: '041 - Banrisul - Banco do Estado do Rio Grande do Sul',
  },
  {
    value: '047',
    text: '047 - Banco do Estado de Sergipe',
  },
  {
    value: '060',
    text: '060 - Confidence',
  },
  {
    value: '062',
    text: '062 - Hipercard',
  },
  {
    value: '063',
    text: '063 - Banco Bradescard',
  },
  {
    value: '064',
    text: '064 - Goldman Sachs',
  },
  {
    value: '065',
    text: '065 - Banco AndBank',
  },
  {
    value: '066',
    text: '066 - Banco Morgan Stanley',
  },
  {
    value: '069',
    text: '069 - Banco Crefisa',
  },
  {
    value: '070',
    text: '070 - Banco de Bras\u00edlia',
  },
  {
    value: '074',
    text: '074 - Banco J. Safra',
  },
  {
    value: '075',
    text: '075 - Banco ABN Amro',
  },
  {
    value: '076',
    text: '076 - Banco KDB',
  },
  {
    value: '078',
    text: '078 - Haitong',
  },
  {
    value: '079',
    text: '079 - Banco Original do Agroneg\u00f3cio',
  },
  {
    value: '080',
    text: '080 - BT',
  },
  {
    value: '081',
    text: '081 - BBN',
  },
  {
    value: '082',
    text: '082 - Banco Topazio',
  },
  {
    value: '083',
    text: '083 - Banco da China',
  },
  {
    value: '084',
    text: '084 - Uniprime Norte do Paran\u00e1',
  },
  {
    value: '085',
    text: '085 - Cecred',
  },
  {
    value: '089',
    text: '089 - Credisan',
  },
  {
    value: '091',
    text: '091 - Cecrers',
  },
  {
    value: '092',
    text: '092 - Brickell',
  },
  {
    value: '093',
    text: '093 - P\u00f3locred',
  },
  {
    value: '094',
    text: '094 - Banco Finaxis',
  },
  {
    value: '095',
    text: '095 - Banco Confidence',
  },
  {
    value: '096',
    text: '096 - Banco BMFBovespa',
  },
  {
    value: '097',
    text: '097 - Credisis',
  },
  {
    value: '098',
    text: '098 - Credialian\u00e7a',
  },
  {
    value: '099',
    text: '099 - Uniprime Central',
  },
  {
    value: '100',
    text: '100 - Planner',
  },
  {
    value: '101',
    text: '101 - Renascen\u00e7a',
  },
  {
    value: '102',
    text: '102 - XP Investimentos',
  },
  {
    value: '105',
    text: '105 - Lecca',
  },
  {
    value: '107',
    text: '107 - Banco BBM',
  },
  {
    value: '108',
    text: '108 - PortoCred',
  },
  {
    value: '111',
    text: '111 - Oliveira Trust',
  },
  {
    value: '113',
    text: '113 - Magliano',
  },
  {
    value: '114',
    text: '114 - CECOOP',
  },
  {
    value: '117',
    text: '117 - Advanced',
  },
  {
    value: '118',
    text: '118 - Standard Chartered Bank',
  },
  {
    value: '119',
    text: '119 - Banco Western Union',
  },
  {
    value: '120',
    text: '120 - Banco Rodobens',
  },
  {
    value: '121',
    text: '121 - Banco Agiplan',
  },
  {
    value: '122',
    text: '122 - Banco Bradesco BERJ',
  },
  {
    value: '124',
    text: '124 - Banco Woori Bank',
  },
  {
    value: '125',
    text: '125 - Brasil Plural',
  },
  {
    value: '126',
    text: '126 - BR Partners',
  },
  {
    value: '127',
    text: '127 - Codepe',
  },
  {
    value: '128',
    text: '128 - MS Bank',
  },
  {
    value: '129',
    text: '129 - UBS',
  },
  {
    value: '130',
    text: '130 - Caruana',
  },
  {
    value: '131',
    text: '131 - Tullett Prebon',
  },
  {
    value: '132',
    text: '132 - ICBC',
  },
  {
    value: '133',
    text: '133 - Confesol',
  },
  {
    value: '134',
    text: '134 - BGC',
  },
  {
    value: '135',
    text: '135 - Gradual',
  },
  {
    value: '136',
    text: '136 - Unicred',
  },
  {
    value: '137',
    text: '137 - Multimoney Ltda',
  },
  {
    value: '138',
    text: '138 - Get Money',
  },
  {
    value: '139',
    text: '139 - Intesa Sanpaolo',
  },
  {
    value: '140',
    text: '140 - Easynvest',
  },
  {
    value: '142',
    text: '142 - Broker',
  },
  {
    value: '143',
    text: '143 - Treviso',
  },
  {
    value: '144',
    text: '144 - Bexs',
  },
  {
    value: '145',
    text: '145 - Levycam',
  },
  {
    value: '146',
    text: '146 - Guitta',
  },
  {
    value: '149',
    text: '149 - Facta Financeira',
  },
  {
    value: '157',
    text: '157 - ICAP',
  },
  {
    value: '159',
    text: '159 - Casa do Cr\u00e9dito',
  },
  {
    value: '163',
    text: '163 - Commerzbank',
  },
  {
    value: '169',
    text: '169 - Banco Ol\u00e9 Bonsucesso',
  },
  {
    value: '172',
    text: '172 - Albatross S.A',
  },
  {
    value: '173',
    text: '173 - BRL Trust',
  },
  {
    value: '174',
    text: '174 - Pernambucanas',
  },
  {
    value: '177',
    text: '177 - Guide Investimentos',
  },
  {
    value: '180',
    text: '180 - CM Capital Markets',
  },
  {
    value: '182',
    text: '182 - Dacasa Financeira',
  },
  {
    value: '183',
    text: '183 - Socred',
  },
  {
    value: '184',
    text: '184 - Banco Ita\u00fa BBA',
  },
  {
    value: '188',
    text: '188 - Ativa Investimentos',
  },
  {
    value: '189',
    text: '189 - HS Financeira',
  },
  {
    value: '190',
    text: '190 - Servicoop',
  },
  {
    value: '191',
    text: '191 - Nova Futura',
  },
  {
    value: '194',
    text: '194 - Parmetal',
  },
  {
    value: '196',
    text: '196 - Fair',
  },
  {
    value: '197',
    text: '197 - Stone Pagamentos',
  },
  {
    value: '204',
    text: '204 - Banco Bradesco Cart\u00f5es',
  },
  {
    value: '208',
    text: '208 - Banco BTG Pactual',
  },
  {
    value: '212',
    text: '212 - Banco Original',
  },
  {
    value: '213',
    text: '213 - Banco Arbi',
  },
  {
    value: '217',
    text: '217 - Banco John Deere',
  },
  {
    value: '218',
    text: '218 - Banco BS2',
  },
  {
    value: '222',
    text: '222 - Banco Credit Agr\u00edcole',
  },
  {
    value: '224',
    text: '224 - Banco Fibra',
  },
  {
    value: '233',
    text: '233 - Banco Cifra',
  },
  {
    value: '241',
    text: '241 - Banco Cl\u00e1ssico',
  },
  {
    value: '243',
    text: '243 - Banco M\u00e1xima',
  },
  {
    value: '246',
    text: '246 - Banco ABC',
  },
  {
    value: '248',
    text: '248 - Banco Boavista Interatl\u00e2ntico',
  },
  {
    value: '249',
    text: '249 - Banco Investcred Unibanco',
  },
  {
    value: '250',
    text: '250 - Banco de Cr\u00e9dito e Varejo',
  },
  {
    value: '253',
    text: '253 - Bexs Corretora de C\u00e2mbio',
  },
  {
    value: '254',
    text: '254 - Parana Banco',
  },
  {
    value: '265',
    text: '265 - Banco Fator',
  },
  {
    value: '266',
    text: '266 - Banco C\u00e9dula',
  },
  {
    value: '268',
    text: '268 - Barigui Companhia Hipotec\u00e1ria',
  },
  {
    value: '290',
    text: '290 - PagSeguro Internet S.A.',
  },
  {
    value: '300',
    text: '300 - Banco de la Nacion Argentina',
  },
  {
    value: '318',
    text: '318 - Banco BMG',
  },
  {
    value: '320',
    text: '320 - China Construction Bank',
  },
  {
    value: '323',
    text: '323 - MercadoPago.com Representa\u00e7\u00f5es Ltda',
  },
  {
    value: '335',
    text: '335 - Banco Digio S.A.',
  },
  {
    value: '336',
    text: '336 - C6 Bank S.A.',
  },
  {
    value: '366',
    text: '366 - Banco Soci\u00e9t\u00e9 G\u00e9n\u00e9rale',
  },
  {
    value: '370',
    text: '370 - Banco Mizuho',
  },
  {
    value: '376',
    text: '376 - Banco JPMorgan',
  },
  {
    value: '380',
    text: '380 - PICPAY SERVICOS S.A.',
  },
  {
    value: '389',
    text: '389 - Banco Mercantil',
  },
  {
    value: '394',
    text: '394 - Banco Bradesco Financiamentos',
  },
  {
    value: '399',
    text: '399 - Kirton Bank',
  },
  {
    value: '412',
    text: '412 - Banco Capital',
  },
  {
    value: '422',
    text: '422 - Banco Safra',
  },
  {
    value: '456',
    text: '456 - Banco de Tokyo-Mitsubishi UFJ',
  },
  {
    value: '464',
    text: '464 - Banco Sumitomo Mitsui',
  },
  {
    value: '473',
    text: '473 - Banco Caixa Geral',
  },
  {
    value: '477',
    text: '477 - Citibank',
  },
  {
    value: '479',
    text: '479 - Banco ItauBank',
  },
  {
    value: '487',
    text: '487 - Deutsche Bank',
  },
  {
    value: '488',
    text: '488 - JPMorgan Chase Bank',
  },
  {
    value: '492',
    text: '492 - ING Bank N.V.',
  },
  {
    value: '494',
    text: '494 - Banco de La Republica Oriental del Uruguay',
  },
  {
    value: '495',
    text: '495 - Banco de La Provincia de Buenos Aires',
  },
  {
    value: '505',
    text: '505 - Banco Credit Suisse',
  },
  {
    value: '545',
    text: '545 - Senso Corretora',
  },
  {
    value: '600',
    text: '600 - Banco Luso Brasileiro',
  },
  {
    value: '604',
    text: '604 - Banco Industrial',
  },
  {
    value: '610',
    text: '610 - Banco VR',
  },
  {
    value: '611',
    text: '611 - Banco Paulista',
  },
  {
    value: '612',
    text: '612 - Banco Guanabara',
  },
  {
    value: '613',
    text: '613 - Omni Banco',
  },
  {
    value: '623',
    text: '623 - Banco Pan',
  },
  {
    value: '626',
    text: '626 - Banco Ficsa',
  },
  {
    value: '630',
    text: '630 - Banco Intercap',
  },
  {
    value: '633',
    text: '633 - Banco Rendimento',
  },
  {
    value: '634',
    text: '634 - Banco Tri\u00e2ngulo',
  },
  {
    value: '637',
    text: '637 - Banco Sofisa',
  },
  {
    value: '641',
    text: '641 - Banco Alvorada',
  },
  {
    value: '643',
    text: '643 - Banco Pine',
  },
  {
    value: '652',
    text: '652 - Ita\u00fa Unibanco Holding',
  },
  {
    value: '653',
    text: '653 - Banco Indusval',
  },
  {
    value: '654',
    text: '654 - Banco Renner',
  },
  {
    value: '655',
    text: '655 - Banco Votorantim',
  },
  {
    value: '20855875',
    text: '655 - Banco Neon',
  },
  {
    value: '707',
    text: '707 - Banco Daycoval',
  },
  {
    value: '712',
    text: '712 - Banco Ourinvest',
  },
  {
    value: '719',
    text: '719 - Banif',
  },
  {
    value: '739',
    text: '739 - Banco Cetelem',
  },
  {
    value: '741',
    text: '741 - Banco Ribeir\u00e3o Preto',
  },
  {
    value: '743',
    text: '743 - Banco Semear',
  },
  {
    value: '745',
    text: '745 - Banco Citibank',
  },
  {
    value: '746',
    text: '746 - Banco Modal',
  },
  {
    value: '747',
    text: '747 - Banco Rabobank',
  },
  {
    value: '748',
    text: '748 - Sicredi',
  },
  {
    value: '751',
    text: '751 - Scotiabank',
  },
  {
    value: '752',
    text: '752 - Banco BNP Paribas',
  },
  {
    value: '753',
    text: '753 - Banco Continental',
  },
  {
    value: '754',
    text: '754 - Banco Sistema',
  },
  {
    value: '755',
    text: '755 - Bank of America',
  },
  {
    value: '756',
    text: '756 - Bancoob',
  },
  {
    value: '757',
    text: '757 - Banco Keb Hana',
  },
  {
    value: '403',
    text: '403 - Cora SCD S.A.',
  },
  {
    value: '364',
    text: '364 - Gerencianet S.A',
  },
  {
    value: '322',
    text: '322 - Sulcredi',
  },
  {
    value: '329',
    text: '329 - QI Sociedade de Crédito Direto S.A',
  },
  {
    value: '529',
    text: '529 - Banco Pinbank IP',
  },
  {
    value: '274',
    text: '274 - Money Plus',
  },
  {
    value: '482',
    text: '482 - SBCASH SOCIEDADE DE CRÉDITO DIRETO S.A.'
  }
];

export { banks };
